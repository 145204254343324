import React, { useState } from 'react';
import axios from 'axios';//back
import '../styles/addclubform.css'
const AddClubForm = ({ onClose }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        city: '',
        name: '',
        tel: '',
        email: ''
    });

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isChecked) {
            alert('Пожалуйста, подтвердите ознакомление с документами.');
            return;
        }

        try {
            axios.post('https://api.pricefit.ru/api/tgBot', formData).then(response => {
                if (response.status == 200) {
                    // alert('Спасибо! Ваша заявка принята. Мы свяжемся с вами в ближайшее время.');
                    document.getElementById('di0').style.display = 'none';
                    document.getElementById('di1').style.display = 'none';
                    document.getElementById('di2').style.display = 'block';
                    setTimeout(function(){ window.location.reload(); }, 4000);
                    console.log(response);
                } else {
                    alert('Что-то пошло не так. Попробуйте отправить форму ещё раз.');
                    console.log(response);
                }
            });
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Что-то пошло не так. Попробуйте отправить форму ещё раз.');
        }
    };

    return (
        <>
            <form className="form" onSubmit={handleSubmit}>
                <div className="addclubform">
                    <button className='close' onClick={onClose}>x</button>
                    <div className='addform_header' id='di0'>
                        Заявка на регистрацию клуба
                    </div>
                    <div className='addform_content' id='di1'>
                        <input
                            className="addform_input"
                            type="text"
                            name="city"
                            placeholder='Город'
                            value={formData.city}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="addform_input"
                            type="text"
                            name="name"
                            placeholder='Ваше имя'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="addform_input"
                            type="tel"
                            name="tel"
                            placeholder='Номер телефона'
                            value={formData.tel}
                            onChange={handleChange}
                            maxLength="12"
                            required
                        />
                        <input
                            className="addform_input"
                            type="text"
                            name="email"
                            placeholder='E-mail'
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <div className='confirm'>
                            <input
                                type="checkbox"
                                id="docs"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label for="docs">Ознакомлен(а) с
                                <a href="#">
                                    <span className="footerinfogreen"> Пользовательским соглашением</span>
                                </a> и
                                <a href="#">
                                    <span className="footerinfogreen"> Политикой обработки персональных данных</span>
                                </a>
                            </label>
                        </div>
                        <button type='submit' className={`send ${isChecked ? 'active' : ''}`}>Отправить</button>
                    </div>
                    <div className='addclubformtnx' id='di2'>Спасибо! Ваша заявка принята. Мы свяжемся с вами в ближайшее время.</div>
                </div>
            </form>
        </>
    );
};
export default AddClubForm;