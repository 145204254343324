import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth';
import { AllPlaces } from "./components/all places/AllPlaces";
import { FieldPageConst } from './components/field page/FieldPageConst';
import { NotFound } from './components/NotFound';
import LogIn from "./components/admin/LogIn";
import { Admin } from './components/admin/Admin';
import { MainPage } from "./components/MainPage";
import ScrollToTop from "./components/ScrollToTop";
import TnxPage from "./components/field page/TnxPage";

function PrivateRoute({ children }) {
  const { auth, login } = useAuth();
  // console.log("privateroute:"); console.log(auth);
  return auth ? children : <Navigate to="/log-in" />;
}

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      sity: "Saratov"
    };
    this.selectSity = this.selectSity.bind(this);
  }

  selectSity(sity) {
    //alert(sity);
    this.setState({ sity: sity });
    //window.location.reload();
  }
  render() {
    return (
      <div className="wrapper" id="wrapperMain">
        <div id="overlay"></div>
        <ScrollToTop />
        <AuthProvider>
          <Routes>
            <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
            <Route path='/' element={
              <>
                <Header showCity={true} selectSity={this.selectSity} />
                <MainPage />
                <Footer />
              </>
            } />
            <Route path="/all-places" element={
              <>
                <Header hideButton={true} showCity={false} selectSity={this.selectSity} />
                <AllPlaces key={this.state.sity} Sity={this.state.sity} />
                <Footer />
              </>
            } />
            <Route path="/field-page/:id" element={
              <>
                <Header showCity={true} selectSity={this.selectSity} />
                <FieldPageConst />
                <Footer />
              </>
            } />
            <Route path='*' element={
              <NotFound />
            } />
            <Route path='/log-in' element={
              <LogIn />
            } />
            <Route path='/tnank-page' element={
              <TnxPage />
            } />
          </Routes>
        </AuthProvider>
      </div>
    )
  }
}
export default App;
