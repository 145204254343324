import React from 'react';
import { useParams } from 'react-router-dom';
import { FieldPage } from './FieldPage';

const FieldPageConst = () => {
    const {id} = useParams();
    return (
        <FieldPage itemid={id} />
    )
}

export { FieldPageConst }
