import React, { useState } from 'react'
import footertelegram from '../image/socialmediafooter.png'
import footerinst from '../image/socialmediafooterINST.png'
import footervk from '../image/socialmediafooterVK.png'
import AddClubForm from './AddClubForm'
export default function Footer() {
  const [showOverlay, setShowOverlay] = useState(false);
  const handleLinkClick = () => {
    setShowOverlay(true);
    var overlay = document.getElementById("overlay");
    overlay.style.display = "block";
    document.body.style.overflow = "hidden";
    document.getElementById("overlay").classList.add('bluroverlay');
    document.getElementById("overlay").addEventListener("click", handleCloseOverlay);
  };
  const handleCloseOverlay = () => {
    setShowOverlay(false);
    var overlay = document.getElementById("overlay");
    overlay.style.display = "none";
    document.body.style.overflow = "auto";
    document.getElementById("overlay").classList.remove('bluroverlay');
    document.getElementById("overlay").removeEventListener("click", handleCloseOverlay);
  };
  return (
    <>
      <footer>
        <div className="footerWrapper">
          <div className="footernavigation">
            <div className="footercollumn">
              <div className="footerTitle">
                Виды спорта
              </div>
              <div className="footerlinks">
                <a href="">Футбол</a>
                <a href="">Теннис</a>
                <a href="">Волейбол</a>
                <a href="">Баскетбол</a>
              </div>
            </div>
            <div className="footercollumn">
              <div className="footerTitle">
                Информация
              </div>
              <div className="footerlinks">
                <a href="">Договор оферты</a>
                <div href="#" id='footer_addfield' onClick={handleLinkClick}>Добавить площадку</div>
              </div>
            </div>
            <div className="footercollumn new-string">
              <div className="footerTitle">
                Собрать команду или найти партнера
                по игре можно в нашей закрытой группе
                в Telegram
              </div>
              <div className="footerlinks">
                <a href=""><img src={footertelegram} alt="" /></a>
              </div>
            </div>
            <div className="footercollumn fotcollast">
              <div className="footerTitle">
                Контакты
              </div>
              <div className="footerlinks footersociallinks">
                <a href=""><img src={footervk} alt="" /></a>
                {/* <a href=""><img src={footerinst} alt="" /></a> */}
                <a href=""><img src={footertelegram} alt="" /></a>
              </div>
            </div>
          </div>
          <div className="footerline"></div>
          <div className="footerinfo">
            <b>Pricefit</b> — сервис онлайн-бронирования спортивных площадок.
            © 2023 Использование сайта означает согласие с <a href="#"><span className="footerinfogreen">Пользовательским
              соглашением</span></a> и <a href="#"><span className="footerinfogreen">Политикой обработки персональных данных</span></a>
          </div>
        </div>
      </footer>
      {showOverlay && <AddClubForm onClose={handleCloseOverlay} />}
    </>
  )
}
