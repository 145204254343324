import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FieldPageConst } from '../field page/FieldPageConst';
import { Widget } from '../Widget';
import { format } from 'date-fns';//back
export class Item extends Component {
  render() {
    return (
      <>
        <Link to={`/field-page/` + this.props.item.id}>
          <div className='item'>
            <div className='imemcol1'>
              <img className='itemcover' src={"./img/" + this.props.item.image} alt='photoPlace' />
            </div>
            <div className='imemcol2'>
              <p>{this.props.item.name}</p>
              <p className='widgetprice'>Свободные сеансы на {this.props.selectedDate.at(1) ? this.props.selectedDate.at(1) : format(new Date(), 'dd.MM.yyyy')}</p>
              <Widget idPlace={this.props.item.id} hideCalendar={true} selectedDate={this.props.selectedDate} />
            </div>
            <div className='imemcol3'>
              <div className='itemminprice'>
                Стоимость часа от <br />
                <p>{this.props.item.standartPrice}₽</p>
              </div>
              <div className='mainbutton'>Подробнее</div>
            </div>
          </div>
        </Link>
      </>
    )
  }
}

export default Item