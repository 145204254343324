import React, { Component } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';//back
class BookWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '+7 ',
      isValidPhone: true,
      showError: false
    };
  }

  handleChange = (e) => {
    let inputValue = e.target.value;
    // Удаляем все символы, кроме цифр
    inputValue = inputValue.replace(/\D/g, '');
    // Если первые два символа - "+7", то оставляем их
    if (inputValue.startsWith('7')) {
      inputValue = '+7' + inputValue.substring(1);
    } else if (!inputValue.startsWith('+7')) {
      inputValue = '+7' + inputValue;
    }
    // Проверяем, соответствует ли введенное значение формату российского номера телефона
    const phoneRegex = /^((\+7|7|8)+([0-9]){10})$/;
    const isValidPhone = phoneRegex.test(inputValue);
    this.setState({ phoneNumber: inputValue, isValidPhone });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { isValidPhone, phoneNumber } = this.state;
    if (!isValidPhone) {
      this.setState({ showError: true });
    } else {
      // Действия при успешной валидации, например, отправка формы
      //console.log('Номер телефона:', phoneNumber);
      // Здесь может быть ваша логика обработки данных формы
    }
  };

  sendVisit = async (data, place) => {

    const getFrontTime = (time) => { // так же сюда можно добавить проверку ебанного времени
      let hour = 0;
      let min = time;
      while (min >= 60) {
        hour++;
        min -= 60;
      }
      if (hour >= 24) {
        hour -= 24;
      }
      if (min < 10) {
        min = '0' + min;
      }
      return hour + ":" + min;
    }
    try {
      var isGoodInformation = false;
        let inputValue = document.getElementById("phone").value;
        inputValue = inputValue.replace(/\D/g, '');
        if (inputValue.startsWith('7')) {
          inputValue = '+7' + inputValue.substring(1);
        } else if (!inputValue.startsWith('+7')) {
          inputValue = '+7' + inputValue;
        }
        const phoneRegex = /^((\+7|7|8)+([0-9]){10})$/;
        const isValidPhone = phoneRegex.test(inputValue) && inputValue.length > 8;
        isGoodInformation = isValidPhone;
        // console.log(isValidPhone);
        if (!isValidPhone) {
          this.setState({ showError: true });
        }
      if (document.getElementById("nameInput").value === "") {
        document.getElementById("nameInput").style.border = "red 2px solid";
        isGoodInformation = false;
      } else {
        document.getElementById("nameInput").style.border = "rgb(118, 118, 118) 1px solid";
        isGoodInformation = isGoodInformation && true;
      }
      if (this.props.preparedVisit.at(0) !== undefined){

      }
      if (isGoodInformation) {
        //проверка на запись
        const response = await axios.post('https://api.pricefit.ru/api/new', data);
        //console.log('Ответ сервера:', response.data);
        if (response.data['status'] === "success") {
          var div = document.getElementById("bookwidget");
          var overlay = document.getElementById("overlay");
          div.classList.remove("bookwidget_mobile");
          overlay.style.display = "none";
          document.body.style.overflow = "auto";
          const sendedData = {
            nameClient: document.getElementById("nameInput").value,
            date: this.props.preparedVisit.at(0),
            price: this.props.preparedVisit.at(3),
            time: getFrontTime(this.props.preparedVisit.at(1)) + "-" + getFrontTime(this.props.preparedVisit.at(2)),
            name: this.props.place.at(0),
            address: this.props.place.at(1)
          };
          this.props.navigate('/tnank-page', { state: sendedData });
        } else {
          alert("Проверьте корректность введенных данных");
        }
        //проверка на запись 
      }

    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  render() {
    const { phoneNumber, isValidPhone, showError } = this.state;
    // якорная ссылка
    const bwAncor = () => {
      const bookingWidget = document.getElementById("booking_widget");
      bookingWidget.scrollIntoView({ behavior: "smooth" });
    };
    // открытие формы бронирования 
    const openForm = () => {
      var div = document.getElementById("bookwidget");
      var overlay = document.getElementById("overlay");
      div.classList.add("bookwidget_mobile");
      overlay.style.display = "block";
      document.body.style.overflow = "hidden";
      document.getElementById("overlay").classList.add('bluroverlay');
      document.getElementById("overlay").addEventListener("click", closeForm);
    }
    // закрытие формы бронирования 
    function closeForm() {
      var div = document.getElementById("bookwidget");
      var overlay = document.getElementById("overlay");
      div.classList.remove("bookwidget_mobile");
      overlay.style.display = "none";
      document.body.style.overflow = "auto";
      document.getElementById("overlay").classList.remove('bluroverlay');
      document.getElementById("overlay").removeEventListener("click", closeForm);
    }
    const getFrontTime = (time) => { // так же сюда можно добавить проверку ебанного времени
      let hour = 0;
      let min = time;
      while (min >= 60) {
        hour++;
        min -= 60;
      }
      if (hour >= 24) {
        hour -= 24;
      }
      if (min < 10) {
        min = '0' + min;
      }
      return hour + ":" + min;
    }
    return (
      <>
        <div className='bookwidget' id='bookwidget'>
          <div id='close_form'>
            <button onClick={closeForm}>x</button>
          </div>
          <div className='bw_title'>Оформить бронирование:</div>
          <form className='bw_inputs' onSubmit={this.handleSubmit}>
            <div className='bw_input_title' required>
              Ваше имя
            </div>
            <input id="nameInput" type="text" />
            <div className='bw_input_title'>
              Номер телефона
            </div>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={phoneNumber}
              onChange={this.handleChange}
              maxLength="12"
              
            />
            {showError && (
              <div id='bw_error' style={{ color: 'red' }}>Укажите российский мобильный телефон</div>
            )}
            <div className='bw_input_title'>
              E-mail
            </div>
            <input id="emailInput" type="text" />
            <div className='bw_input_title'>
              Дата и время бронирования
            </div>
            {this.props.preparedVisit.at(0) !== undefined && (
              <span>
                {this.props.preparedVisit.at(0) + "   " + getFrontTime(this.props.preparedVisit.at(1)) + "-" + getFrontTime(this.props.preparedVisit.at(2))}
              </span>
            )}

            <div className='bw_input_title'>
              Стоимость
            </div>
            <span>{this.props.preparedVisit.at(3)}₽</span>
            <button type="submit"
              onClick={() => this.sendVisit({
                idPlace: this.props.preparedVisit.at(4),
                Date: this.props.preparedVisit.at(0),//data
                timeStart: this.props.preparedVisit.at(1),//timestart
                timeFinish: this.props.preparedVisit.at(2),//timefinish
                price: this.props.preparedVisit.at(3),//price
                name: document.getElementById("nameInput").value,
                phone: document.getElementById("phone").value,
                email: document.getElementById("emailInput").value
              }, this.props.place)} className='bw_button'>
              Создать запись
            </button>
          </form>
        </div>

        <div className='bw_mobile'>
          {(this.props.preparedVisit.at(0) === undefined) && (
            <button className='bw_m_button' onClick={bwAncor}>Создать запись</button>
          )}

          {(this.props.preparedVisit.at(0) !== undefined) && (
            <div className='bw_next'>
              <div className='bw_m_info'>
                {this.props.preparedVisit.at(0) + ", " + getFrontTime(this.props.preparedVisit.at(1)) + " - " + getFrontTime(this.props.preparedVisit.at(2))}
                <span>{this.props.preparedVisit.at(3)}₽</span>
              </div>
              <button className='bw_m_button bw_m_button_active' onClick={openForm}>Создать запись</button>
            </div>
          )}
        </div>
      </>
    );
  }
}


function BookNavigate(props) {
  const navigate = useNavigate();
  return <BookWidget {...props} navigate={navigate} />;
} export default BookNavigate;
