import React, { useContext, useEffect } from 'react';
import '../../styles/tnxpage.css';
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom';
import { NotFound } from '../NotFound';

export default function TnxPage() {
    const location = useLocation();
    const { state } = location;

    return (
        <>{state && state.address ? (
            <div id="tnxpage">
                <div className='tnx_block'>
                    <div className='tnk_header'>Успешное<br />бронирование!</div>
                    <div className='tnx_info'>
                        <div className='thx_detals'>
                            Ваше имя: <span>{state.nameClient}</span>
                        </div>
                        <div className='thx_detals'>
                            Адрес: <span>{state.address}</span>
                        </div>
                        <div className='thx_detals'>
                            Площадка: <span>{state.name}</span>
                        </div>
                        <div className='thx_detals'>
                            Дата: <span>{state.date}</span>
                        </div>
                        <div className='thx_detals'>
                            Время: <span>{state.time}</span>
                        </div>
                        <div className='thx_detals'>
                            Стоимость: <span>{state.price}</span>
                        </div>
                    </div>
                    <Link to={'/'}>
                        <div className='homelink'>На главную</div>
                    </Link>
                </div>
            </div>
        ) : (
            <NotFound />
        )}</>

    )
}
export { TnxPage }
