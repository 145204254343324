import React, { Component } from 'react'
import Item from './Item'

export class Items extends Component {
  render() {
    return (
      <div className='itemslist'>
        {this.props.items.map(el => (
            <Item key={el.id} item={el} selectedDate={this.props.selectedDate}/>
        ))}
      </div>
    )
  }
}

export default Items
