import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/header.css';
import './styles/footer.css';
import './styles/placessearchfilter.css'
import './styles/item.css';
import './styles/bookwidget.css';
import './styles/fieldpage.css';
import './styles/carousel.css';
import './styles/404.css';
import './styles/admin/login.css';
import './styles/widget.css'
import './styles/admin/admin.css'
import './styles/admin/adbookwidget.css'
import './styles/admin/adanalytics.css'
import './styles/admin/adfields.css'
import './styles/mainpage.css'

// import { ScrollToTop } from 'react-router-scroll-to-top';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter >
      {/* <ScrollToTop > */}
        <App />
      {/* </ScrollToTop> */}
    </BrowserRouter>

);
