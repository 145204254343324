import React, { useState, Suspense, lazy, useContext, createContext } from 'react';
import Adheadericon from '../../image/adminIcons/headerIcon.png';
import { ReactComponent as FieldIcon } from '../../image/adminIcons/fieldIcon.svg';
import { ReactComponent as BooksIcon } from '../../image/adminIcons/booksIcon.svg';
import { ReactComponent as AnalyticsIcon } from '../../image/adminIcons/analyticsIcon.svg';
import { ReactComponent as NoticeIcon } from '../../image/adminIcons/noticeIcon.svg';
import { ReactComponent as NoticeTrueIcon } from '../../image/adminIcons/noticeTrueIcon.svg';
import { ReactComponent as HelpIcon } from '../../image/adminIcons/helpIcon.svg';
import { useAuth } from '../auth';

const AdBookings = lazy(() => import('./AdBookings'));
const AdAnalytics = lazy(() => import('./AdAnalytics'));
const AdFields = lazy(() => import('./AdFields'));
const AdNotifications = lazy(() => import('./AdNotifications')); 


function Admin() {
    let defaultOpen = 'notifications';
    if (localStorage.getItem('componentName') != undefined){
        defaultOpen = localStorage.getItem('componentName');
    }
    const [componentToShow, setComponentToShow] = useState(defaultOpen);
    const { auth, login } = useAuth();
    console.log(auth);
    const idAdmin = auth.id;
    const secretKey = auth.secretKey;

    // Функция обработчик клика для блока "Записи"
    const handleMenuClick = (componentName) => {
        if (componentToShow !== componentName) {
            setComponentToShow(componentName);
        }
    };
    
    // Функция для отображения правильного компонента в зависимости от состояния
    const renderComponent = () => {

        switch (componentToShow) {
            case 'fields':
                return <AdFields idAdmin={idAdmin} secretKey={secretKey} />;
            case 'bookings':
                return <AdBookings idAdmin={idAdmin} secretKey={secretKey} />;
            case 'analytics':
                return <AdAnalytics idAdmin={idAdmin} secretKey={secretKey} />;
            case 'notifications':
                return <AdNotifications idAdmin={idAdmin} secretKey={secretKey} />;
            default:
                return null;
        }
    };

    return (
        <>
            <header className='admin_header'>
                <img src={Adheadericon} alt="Header Icon" />
                Клуб "Лето"
            </header>
            <div className='admin'>
                <div className='admin_leftpanel'>
                    <div className='admin_navigation'>
                        <span onClick={() => handleMenuClick('notifications')} className={componentToShow === 'notifications' ? 'button-active' : 'button-inactive'}>
                            <NoticeIcon id="noticeIcon" className='admin_nav_icon' />
                            <div>Записи</div>
                        </span>
                        <span onClick={() => handleMenuClick('bookings')} className={componentToShow === 'bookings' ? 'button-active' : 'button-inactive'}>
                            <BooksIcon className='admin_nav_icon' />
                            <div>Расписание</div>
                        </span>
                        <span onClick={() => handleMenuClick('analytics')} className={componentToShow === 'analytics' ? 'button-active' : 'button-inactive'}>
                            <AnalyticsIcon className='admin_nav_icon_new' />
                            <div>Аналитика</div>
                        </span>
                        <span onClick={() => handleMenuClick('fields')} className={componentToShow === 'fields' ? 'button-active' : 'button-inactive'}>
                            <FieldIcon className='admin_nav_icon_new' />
                            <div>Поля</div>
                        </span>
                        {/* <span onClick={() => handleMenuClick('help')} className={componentToShow === 'help' ? 'button-active' : 'button-inactive'}>
                            <HelpIcon className='admin_nav_icon' />
                            <div>Помощь</div>
                        </span> */}
                    </div>
                </div>
                <div className='admin_content'>
                    {/* {componentToShow && ( */}
                        <Suspense fallback={<div>Loading...</div>}>
                            {renderComponent()}
                        </Suspense>
                    {/* )} */}
                </div>
            </div>
        </>
    );
}

export { Admin };
