import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import vin from '../image/404.gif'

export default function NotFound() {
    return (
        <div id="errorPage">
            <div id="errorNumber">
                <span>4</span>
                <div>0</div>
                <span>4</span>
            </div>
            <img src={vin} alt="loading..." />

            {/* <i>место, где случайность встречает терпеливость. возможно, ваша цель потерялась по пути, но мы не сдаемся, чтобы вам помочь ее найти</i> */}
            <Link to='/'>
                <div id="homeButton"><b>На главную</b></div>
            </Link>
        </div>
    )
}
export { NotFound }