import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';//back

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => {
    // Считываем состояние аутентификации из localStorage
    const savedAuth = localStorage.getItem("auth");
    console.log('Saved auth:', savedAuth);
    return savedAuth ? JSON.parse(savedAuth) : null;  //ВЕРНУТЬ ЧТОБЫ НЕ ПРОСИЛ ВСЕГДА ВХОД!!!
    
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
    // console.log("useEffect");
    // console.log(auth); 
  }, [auth]);

  const navigate = useNavigate();

  const login = (username, password) => {
    const data =
    {
      "login": username,
      "password": password,
    };
    axios.post('https://api.pricefit.ru/api/getAdmin', data).then(response => {
      // console.log(response.data.at(0));
      const data = response.data.at(0);
      if (data) {
        const id = data.id;
        // console.log("response.data.id" + data.id);
        const secretKey = data.secretKey;
        // console.log("response.data.secretKey" + data.secretKey);
        setAuth({id,secretKey});
        navigate('/admin');
      } else {
        alert('Invalid username or password');
      }
    })
  };

  return (
    <AuthContext.Provider value={{ auth, login }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
