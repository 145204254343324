import headerlogo from '../image/pricefit-logo.png'
import pricefitlogomobile from '../image/pricefit-logo-mobile.png'
import selectcity from '../image/selectCity.png'
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


const Header = ({ hideButton, showCity, selectSity }) => {
    return (
        <>
            <header>
                <div className="header-block">
                    <Link to='/'>
                        <picture>
                            <source media="(max-width: 500px)" srcSet={pricefitlogomobile} className="headerlogo" />
                            <img className="headerlogo" src={headerlogo} alt="pricefitlogo" />
                        </picture>
                    </Link>
                    {!hideButton &&
                        <Link to='/all-places'>
                            <button className="header-places-list">Выбрать площадку
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1_95)">
                                        <path d="M15.6901 2H0.690063C0.554647 2 0.437459 2.04948 0.338501 2.14844C0.239543 2.2474 0.190063 2.36458 0.190063 2.5V13.5C0.190063 13.6354 0.239543 13.7526 0.338501 13.8516C0.437459 13.9505 0.554647 14 0.690063 14H15.6901C15.8255 14 15.9427 13.9505 16.0416 13.8516C16.1406 13.7526 16.1901 13.6354 16.1901 13.5V2.5C16.1901 2.36458 16.1406 2.2474 16.0416 2.14844C15.9427 2.04948 15.8255 2 15.6901 2ZM15.1901 10H13.1901V6H15.1901V10ZM7.69006 9.40625C7.3984 9.30208 7.15881 9.1224 6.97131 8.86719C6.78381 8.61198 6.69006 8.32292 6.69006 8C6.69006 7.67708 6.78381 7.38802 6.97131 7.13281C7.15881 6.8776 7.3984 6.69792 7.69006 6.59375V9.40625ZM8.69006 6.59375C8.98173 6.69792 9.22131 6.8776 9.40881 7.13281C9.59631 7.38802 9.69006 7.67708 9.69006 8C9.69006 8.32292 9.59631 8.61198 9.40881 8.86719C9.22131 9.1224 8.98173 9.30208 8.69006 9.40625V6.59375ZM1.19006 6H3.19006V10H1.19006V6ZM1.19006 11H3.69006C3.82548 11 3.94267 10.9505 4.04163 10.8516C4.14058 10.7526 4.19006 10.6354 4.19006 10.5V5.5C4.19006 5.36458 4.14058 5.2474 4.04163 5.14844C3.94267 5.04948 3.82548 5 3.69006 5H1.19006V3H7.69006V5.54688C7.11715 5.66146 6.64058 5.94792 6.26038 6.40625C5.88017 6.86458 5.69006 7.39583 5.69006 8C5.69006 8.60417 5.88017 9.13542 6.26038 9.59375C6.64058 10.0521 7.11715 10.3385 7.69006 10.4531V13H1.19006V11ZM8.69006 13V10.4531C9.26298 10.3385 9.73954 10.0521 10.1198 9.59375C10.5 9.13542 10.6901 8.60417 10.6901 8C10.6901 7.39583 10.5 6.86458 10.1198 6.40625C9.73954 5.94792 9.26298 5.66146 8.69006 5.54688V3H15.1901V5H12.6901C12.5546 5 12.4375 5.04948 12.3385 5.14844C12.2395 5.2474 12.1901 5.36458 12.1901 5.5V10.5C12.1901 10.6354 12.2395 10.7526 12.3385 10.8516C12.4375 10.9505 12.5546 11 12.6901 11H15.1901V13H8.69006Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_95">
                                            <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0.190063 16)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </Link>
                    }
                    {!showCity &&
                        <div className='selectcity'>
                            <img src={selectcity} />
                            <select id="selectcity"
                                onChange={(e) => { selectSity(e.target.value)}}
                                //onChange={(e) => { callSelectSity() }}
                            >
                                <option value="Saratov" >Саратов</option>
                                <option value="Engels">Энгельс</option>
                            </select>
                        </div>
                    }
                </div>
            </header>
        </>
    )
}
export default Header