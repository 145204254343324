import React from 'react'
import axios from 'axios';//back
import { format, addDays, constructNow } from 'date-fns';//back
import { ru } from 'date-fns/locale';//back


const today = new Date();

const calendarForWidget = [
    [today.getDay(), "Сегодня", getFrontDay(today.getDay()), format(today, 'dd.MM.yyyy')],
    [addDays(today, 1).getDay(), "Завтра", getFrontDay(addDays(today, 1).getDay()), format(addDays(today, 1), 'dd.MM.yyyy')],
    [addDays(today, 2).getDay(), format(addDays(today, 2), 'dd MMMM', { locale: ru }), getFrontDay(addDays(today, 2).getDay()), format(addDays(today, 2), 'dd.MM.yyyy')],
    [addDays(today, 3).getDay(), format(addDays(today, 3), 'dd MMMM', { locale: ru }), getFrontDay(addDays(today, 3).getDay()), format(addDays(today, 3), 'dd.MM.yyyy')],
    [addDays(today, 4).getDay(), format(addDays(today, 4), 'dd MMMM', { locale: ru }), getFrontDay(addDays(today, 4).getDay()), format(addDays(today, 4), 'dd.MM.yyyy')],
    [addDays(today, 5).getDay(), format(addDays(today, 5), 'dd MMMM', { locale: ru }), getFrontDay(addDays(today, 5).getDay()), format(addDays(today, 5), 'dd.MM.yyyy')]
];

function getFrontDay(day) {
    switch (day) {
        case 0: { return "Вс"; }
        case 1: { return "Пн"; }
        case 2: { return "Вт"; }
        case 3: { return "Ср"; }
        case 4: { return "Чт"; }
        case 5: { return "Пт"; }
        case 6: { return "Сб"; }
        default: { return "Error day" }
    }
}
function getBackDay(day) {
    switch (day) {
        case 0: { return "sunday"; }
        case 1: { return "monday"; }
        case 2: { return "tuesday"; }
        case 3: { return "wednesday"; }
        case 4: { return "thursday"; }
        case 5: { return "friday"; }
        case 6: { return "saturday"; }
        default: { return "Error day" }
    }
}

class Widget extends React.Component {
    //this.props.  idPlace / standartPrice
    constructor(props) {
        super(props)
        this.state = {
            place: [],
            cells: [],//id time price status (open close select) + interval
            selectedCells: [],
            dateNow: "",
            preparedVisit: [],
            selectedDate: []
        };
    }

    getFrontTime = (time) => { // так же сюда можно добавить проверку ебанного времени
        let hour = 0;
        let min = time;
        while (min >= 60) {
            hour++;
            min -= 60;
        }
        if (hour >= 24) {
            hour -= 24;
        }
        if (min < 10) {
            min = '0' + min;
        }
        return hour + ":" + min;
    }

    updateWidget = (day, selectedDate) => {
        console.log("updateWidget: " + day + " / " + selectedDate);
        this.setState({ dateNow: selectedDate });
        this.setState({ selectedCells: [] });
        axios.get('https://api.pricefit.ru/api/widget/' + this.props.idPlace + '/' + getBackDay(day))
            .then(response => {

                this.setState({ place: response.data }, () => {
                    //console.log(this.state.place);
                    let tmpCells = [];
                    let selectedIntervalId = 0,
                        tmpTime = this.state.place.at(selectedIntervalId).dayStart;


                    for (let i = 0; tmpTime < this.state.place.at(this.state.place.length - 1).dayFinish; i++) {
                        //заполнение по стандартному времени по стандартному интервалу
                        //отсутствие возможности записать на сегодня, но время уже прошло
                        if (this.state.dateNow === format(today, 'dd.MM.yyyy') &&
                            tmpTime + this.state.place.at(selectedIntervalId).intervalTime < today.getHours() * 60 + today.getMinutes()) {
                            // tmpTime = today.getHours() * 60 + today.getMinutes();
                        } else {
                            tmpCells.push([i, tmpTime, this.state.place.at(selectedIntervalId).price, 'open', this.state.place.at(selectedIntervalId).intervalTime]);
                        }
                        //отсутствие возможности записать на сегодня, но время уже прошло
                        // tmpCells.push([i, tmpTime, this.state.place.at(selectedIntervalId).price, 'open', this.state.place.at(selectedIntervalId).intervalTime]);
                        tmpTime += this.state.place.at(selectedIntervalId).intervalTime;
                        if (selectedIntervalId !== this.state.place.length - 1) {
                            if (tmpTime >= this.state.place.at(selectedIntervalId + 1).timeStart) {
                                selectedIntervalId++;
                            }
                        }
                    }

                    axios.get('https://api.pricefit.ru/api/widgetdate/' + this.props.idPlace + '/' + selectedDate)
                        .then(response => {

                            if (response.data.at(0) !== undefined) {
                                let visits = response.data,
                                    visitId = 0;
                                let tmpCellsSecound = [];

                                for (let i = 0; i < tmpCells.length - 1; i++) {
                                    if (visits.at(visitId).timeStart < tmpCells.at(i).at(1) &&
                                        visits.at(visitId).timeFinish < tmpCells.at(i + 1).at(1)) {
                                        if (visitId + 1 !== visits.length) {
                                            visitId++;
                                        }
                                    }
                                    if (visits.at(visitId).timeStart <= tmpCells.at(i).at(1) &&
                                        visits.at(visitId).timeFinish >= tmpCells.at(i + 1).at(1) &&
                                        (visits.at(visitId).status === "submit" || visits.at(visitId).status === "new")) {
                                        tmpCellsSecound.push([tmpCells.at(i).at(0), tmpCells.at(i).at(1), tmpCells.at(i).at(2), "close", tmpCells.at(i).at(4)]);
                                    } else {
                                        tmpCellsSecound.push(tmpCells.at(i));
                                    }
                                }
                                if (visits.at(visitId).timeStart < tmpCells.at(tmpCells.length - 1).at(1) &&
                                    visits.at(visitId).timeFinish < this.state.place.at(this.state.place.length - 1).dayFinish) {
                                    if (visitId + 1 !== visits.length) {
                                        visitId++;
                                    }
                                }
                                if (visits.at(visitId).timeStart <= tmpCells.at(tmpCells.length - 1).at(1) &&
                                    visits.at(visitId).timeFinish >= this.state.place.at(this.state.place.length - 1).dayFinish &&
                                    (visits.at(visitId).status === "submit" || visits.at(visitId).status === "new")) {
                                    tmpCellsSecound.push([tmpCells.at(tmpCells.length - 1).at(0), tmpCells.at(tmpCells.length - 1).at(1), tmpCells.at(tmpCells.length - 1).at(2), "close", tmpCells.at(tmpCells.length - 1).at(4)]);
                                } else {
                                    tmpCellsSecound.push(tmpCells.at(tmpCells.length - 1));
                                }
                                tmpCells = tmpCellsSecound;
                            }
                            this.setState({ cells: tmpCells }, () => { }); //console.log(this.state.cells);
                        }
                        );

                })
            }
            );
    }
    async componentDidUpdate() {
        if (this.props.selectedDate) {
            //console.log("Did Update: " + this.props.selectedDate);
            if (this.state.dateNow !== this.props.selectedDate.at(1)) {
                this.updateWidget(this.props.selectedDate.at(0), this.props.selectedDate.at(1));
            }
        }
    }
    async componentDidMount() {
        this.setState({ dateNow: format(today, 'dd.MM.yyyy') });
        this.setState({ selectedCells: [] });
        // console.log(today.getHours() + " " + today.getMinutes());
        axios.get('https://api.pricefit.ru/api/widget/' + this.props.idPlace + '/' + getBackDay(today.getDay()))
            .then(response => {
                this.setState({ place: response.data }, () => {
                    let tmpCells = [];
                    let selectedIntervalId = 0,
                        tmpTime = this.state.place.at(selectedIntervalId).dayStart;


                    for (let i = 0; tmpTime < this.state.place.at(this.state.place.length - 1).dayFinish; i++) {
                        //заполнение по стандартному времени по стандартному интервалу
                        //отсутствие возможности записать на сегодня, но время уже прошло
                        if (this.state.dateNow === format(today, 'dd.MM.yyyy') &&
                            tmpTime + this.state.place.at(selectedIntervalId).intervalTime < today.getHours() * 60 + today.getMinutes()) {
                            // tmpTime = today.getHours() * 60 + today.getMinutes();
                        } else {
                            tmpCells.push([i, tmpTime, this.state.place.at(selectedIntervalId).price, 'open', this.state.place.at(selectedIntervalId).intervalTime]);
                        }
                        //отсутствие возможности записать на сегодня, но время уже прошло
                        tmpTime += this.state.place.at(selectedIntervalId).intervalTime;
                        if (selectedIntervalId !== this.state.place.length - 1) {
                            if (tmpTime >= this.state.place.at(selectedIntervalId + 1).timeStart) {
                                selectedIntervalId++;
                            }
                        }
                    }

                    axios.get('https://api.pricefit.ru/api/widgetdate/' + this.props.idPlace + '/' + format(today, 'dd.MM.yyyy'))
                        .then(response => {
                            if (response.data.at(0) !== undefined) {
                                let visits = response.data,
                                    visitId = 0;
                                let tmpCellsSecound = [];

                                for (let i = 0; i < tmpCells.length - 1; i++) {
                                    if (visits.at(visitId).timeStart < tmpCells.at(i).at(1) &&
                                        visits.at(visitId).timeFinish < tmpCells.at(i + 1).at(1)) {
                                        if (visitId + 1 !== visits.length) {
                                            visitId++;
                                        }
                                    }
                                    if (visits.at(visitId).timeStart <= tmpCells.at(i).at(1) &&
                                        visits.at(visitId).timeFinish >= tmpCells.at(i + 1).at(1) &&
                                        (visits.at(visitId).status === "submit" || visits.at(visitId).status === "new")) {
                                        tmpCellsSecound.push([tmpCells.at(i).at(0), tmpCells.at(i).at(1), tmpCells.at(i).at(2), "close", tmpCells.at(i).at(4)]);
                                    } else {
                                        tmpCellsSecound.push(tmpCells.at(i));
                                    }
                                }
                                if (visits.at(visitId).timeStart < tmpCells.at(tmpCells.length - 1).at(1) &&
                                    visits.at(visitId).timeFinish < this.state.place.at(this.state.place.length - 1).dayFinish) {
                                    if (visitId + 1 !== visits.length) {
                                        visitId++;
                                    }
                                }
                                if (visits.at(visitId).timeStart <= tmpCells.at(tmpCells.length - 1).at(1) &&
                                    visits.at(visitId).timeFinish >= this.state.place.at(this.state.place.length - 1).dayFinish &&
                                    (visits.at(visitId).status === "submit" || visits.at(visitId).status === "new")) {
                                    tmpCellsSecound.push([tmpCells.at(tmpCells.length - 1).at(0), tmpCells.at(tmpCells.length - 1).at(1), tmpCells.at(tmpCells.length - 1).at(2), "close", tmpCells.at(tmpCells.length - 1).at(4)]);
                                } else {
                                    tmpCellsSecound.push(tmpCells.at(tmpCells.length - 1));
                                }

                                tmpCells = tmpCellsSecound;
                            }
                            this.setState({ cells: tmpCells }, () => {  });//console.log(this.state.cells);
                            }
                            );
                        })
                }
                );
            }

    updateSelectedCells(cell) {
                this.state.cells.map(i => {
                    document.getElementById("cell" + i.at(0)).classList.remove("w_price_select");
                })
        let tmpCells = this.state.selectedCells;

                if(tmpCells.some(item => item[0] === cell[0])) {
            tmpCells = tmpCells.filter(item => item[0] !== cell[0]);
        } else {
            tmpCells.push(cell);
        }

        tmpCells = tmpCells.sort((a, b) => a[0] - b[0]);
        const isInOrder = tmpCells.every((item, index) => {
            if (index === 0) return true;
            return item[0] === tmpCells[index - 1][0] + 1;
        });
        if (!isInOrder) {
            tmpCells = [];
            tmpCells.push(cell);
        }
        // //console.log("Выделенные ячейки:/n");
        // //console.log(tmpCells);
        this.setState({ selectedCells: tmpCells });

        //ТУТ ПРАВИТЬ АНИМАЦИЮ ВЫДЕЛЕНИЯ
        tmpCells.map(i => {
            document.getElementById("cell" + i.at(0)).classList.add("w_price_select");
        })
        //ТУТ ПРАВИТЬ АНИМАЦИЮ ВЫДЕЛЕНИЯ

        if (tmpCells.at(0) !== undefined) {
            let mainTimeStart = tmpCells.at(0).at(1),
                mainTimeFinish = mainTimeStart,
                mainPrice = 0,
                mainData = this.state.dateNow;
            for (let i = 0; i < tmpCells.length; i++) {
                mainTimeFinish += tmpCells.at(i).at(4);
                mainPrice += tmpCells.at(i).at(2);
            }
            this.props.callParentFunction([mainData, mainTimeStart, mainTimeFinish, mainPrice, this.props.idPlace, "name i widget"])
        }

    }

    render() {
        return (
            <div className='price_widget'>
                {!this.props.hideCalendar && <div className='w_dates' >
                    {calendarForWidget.map((d, index) => (
                        <label >
                            <div id={"dateSelect" + index} className={index === 0 ? 'date_active' : 'w_date'} onClick={() => {
                                this.updateWidget(d.at(0), d.at(3));
                                this.state.cells.map(i => {
                                    document.getElementById("cell" + i.at(0)).classList.remove("w_price_select");
                                })
                                document.getElementById("dateSelect0").classList.remove("date_active");
                                document.getElementById("dateSelect1").classList.remove("date_active");
                                document.getElementById("dateSelect2").classList.remove("date_active");
                                document.getElementById("dateSelect3").classList.remove("date_active");
                                document.getElementById("dateSelect4").classList.remove("date_active");
                                document.getElementById("dateSelect5").classList.remove("date_active");
                                document.getElementById("otherDate").classList.remove("date_active");

                                document.getElementById("dateSelect0").classList.add("w_date");
                                document.getElementById("dateSelect1").classList.add("w_date");
                                document.getElementById("dateSelect2").classList.add("w_date");
                                document.getElementById("dateSelect3").classList.add("w_date");
                                document.getElementById("dateSelect4").classList.add("w_date");
                                document.getElementById("dateSelect5").classList.add("w_date");
                                document.getElementById("otherDate").classList.add("w_date");
                                document.getElementById("dateSelect" + index).classList.remove("w_date");
                                document.getElementById("dateSelect" + index).classList.add("date_active");
                            }}>
                                {d.at(1)}
                                <br />
                                <span>{d.at(2)}</span>
                            </div>
                        </label>
                    ))
                    }
                    <label >
                        <div id="otherDate" className='w_date'>
                            Другая дата
                            <br />
                            {/* <span>Выбрать</span> */}
                            <input className='selectdate' type="date" name="calendar" min={format(new Date(), 'yyyy-MM-dd')} onChange={(e) => {
                                if (e.target.value) {
                                    //getDateFilter([new Date(e.target.value).getDay(), format(new Date(e.target.value), 'dd.MM.yyyy')]);
                                    // setDateNow(format(new Date(e.target.value), 'yyyy-MM-dd'));
                                    this.updateWidget(new Date(e.target.value).getDay(), format(new Date(e.target.value), 'dd.MM.yyyy'));
                                    this.state.cells.map(i => {
                                        document.getElementById("cell" + i.at(0)).classList.remove("w_price_select");
                                    })
                                    document.getElementById("dateSelect0").classList.remove("date_active");
                                    document.getElementById("dateSelect1").classList.remove("date_active");
                                    document.getElementById("dateSelect2").classList.remove("date_active");
                                    document.getElementById("dateSelect3").classList.remove("date_active");
                                    document.getElementById("dateSelect4").classList.remove("date_active");
                                    document.getElementById("dateSelect5").classList.remove("date_active");

                                    document.getElementById("dateSelect0").classList.add("w_date");
                                    document.getElementById("dateSelect1").classList.add("w_date");
                                    document.getElementById("dateSelect2").classList.add("w_date");
                                    document.getElementById("dateSelect3").classList.add("w_date");
                                    document.getElementById("dateSelect4").classList.add("w_date");
                                    document.getElementById("dateSelect5").classList.add("w_date");
                                    document.getElementById("otherDate").classList.remove("w_date");
                                    document.getElementById("otherDate").classList.add("date_active");
                                } else {
                                    // getDateFilter([new Date().getDay(), format(new Date(), 'dd.MM.yyyy')]);
                                    // setDateNow(format(new Date(), 'yyyy-MM-dd'));
                                }
                            }}
                            />
                        </div>
                    </label>
                </div>}
                <div className='w_prices'>
                    {this.state.cells.map(cell => (
                        <label onClick={() => {
                            if (!this.props.hideCalendar) {
                                if (cell.at(3) !== "close") {
                                    this.updateSelectedCells(cell)
                                } } }}>
                            <div id={"cell" + cell.at(0)} className={cell.at(3) === "close" ? 'w_price w_price_cheked' : 'w_price'}>
                                {this.getFrontTime(cell.at(1))}
                                <br />
                                <span>{cell.at(2)}₽</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        )
    }
}
export { Widget }